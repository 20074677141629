<template>
    <div>
        <input
            :id="id"
            :value="formattedValue"
            :type="config?.type ?? 'text'"
            :placeholder="config?.placeholder ?? ''"
            class="form-control"
            :class="{
                [config.classCustom]: config.classCustom,
                'is-invalid': config?.error ?? false,
                disabled_cursor: disabled
            }"
            :name="config?.name ?? ''"
            :maxlength="config?.maxlength ?? null"
            :min="config?.min ?? ''"
            :max="config?.max ?? ''"
            :autofocus="config?.autofocus ?? false"
            :autocomplete="config?.autocomplete ?? 'off'"
            :size="config?.size"
            :disabled="disabled"
            @input="onInput"
            @blur="onBlur"
            @keypress="handleKeyPress"
            @change="handleChangeInput"
        />
        <div v-if="config.error">
            <MessageInput :configMessage="config" />
        </div>
    </div>
</template>

<script>
import { isNumber } from '@/utils/format';

import MessageInput from './message.vue';

export default {
    name: 'AmountInput',
    components: {
        MessageInput
    },
    props: {
        id: {
            type: String,
            required: false,
            default: 'amout_input'
        },
        value: {
            type: [Number, String, null]
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false
        },
        config: {
            type: Object,
            required: true,
            default() {
                return {
                    classCustom: 'form-control',
                    name: '',
                    maxlength: 16,
                    type: 'text',
                    placeholder: '',
                    min: '',
                    max: '',
                    isRequired: false,
                    isMaxLength: false,
                    autofocus: false,
                    error: false,
                    errorField: '',
                    errorCustomMess: '',
                    valueSync: '',
                    size: '',
                    typesValidate: []
                };
            }
        }
    },
    computed: {
        formattedValue() {
            if (this.value == 0 || (this.value !== null && this.value !== undefined && Number(this.value))) {
                return Number(this.value).toLocaleString();
            }
            return '';
        }
    },
    watch: {
        formattedValue: {
            handler: function () {
                let config = this.config;
                config.error = false;
                config.isRequired = false;
                config.isMaxLength = false;
                config.errorCustomMess = '';
                config.valueSync = this.value;
                this.$emit('update:config', config);
            },
            immediate: true
        },

        '$props.config': {
            handler: function (newConfig) {
                this.config = newConfig;
                this.$emit('update:config', this.config);
            },
            immediate: true,
            deep: true
        }
    },
    methods: {
        onInput(event) {
            const rawValue = event.target.value.replace(/,/g, '');
            if (/^\d+$/.test(rawValue) || rawValue === '') {
                this.$emit('input', rawValue === '' ? null : Number(rawValue));
            }
        },

        onBlur(event) {
            event.target.value = this.formattedValue;
            this.$emit('blur', this.$props.value);
        },

        handleKeyPress(event) {
            isNumber(event);
            this.$emit('keypress', this.$props.value);
        },

        handleChangeInput() {
            this.$emit('change', this.$props.value);
        }
    }
};
</script>
