<template>
    <div class="invalid-feedback d-block">
        <span v-if="configMessage.isRequired">{{
            $t('validateField.required', {
                field: configMessage.errorField
            })
        }}</span>
        <span v-if="configMessage.isMaxLength">
            {{
                $t('validateField.max255', {
                    field: configMessage.errorField
                })
            }}</span
        >
        <span v-if="configMessage.isKatakana">{{
            $t('validateField.incorrectFormat', {
                field: configMessage.errorField
            })
        }}</span>
        <span v-if="configMessage.isUrl"> {{ $t('validateField.incorrectURL') }}</span>
        <span v-if="configMessage.isEmail">
            {{
                $t('validateField.incorrectFormat', {
                    field: configMessage.errorField
                })
            }}</span
        >
        <span v-if="configMessage.errorCustomMess">
            {{ configMessage.errorCustomMess }}
        </span>
        <span v-if="configMessage.isPassword">{{ $t('notificationCommon.notePass') }}</span>
    </div>
</template>

<script>
export default {
    name: 'MessageInput',
    props: {
        configMessage: {
            type: Object,
            required: true
        }
    }
};
</script>

<style lang="scss" scoped></style>
